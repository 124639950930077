<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "New"} User` }}
            </h4>
            <v-form ref="userForm" @submit.prevent="save()">
              <v-row>
                <v-col cols="2" sm="2" md="2">
                  <base-title-picker
                    color="primary"
                    :label="'Title'"
                    :chosen-title.sync="userModel.title"
                    :initial="userModel.title"
                    v-model="userModel.title"
                    outlined
                  />
                </v-col>
                <v-col cols="4" md="4" sm="4">
                  <v-text-field
                    label="First Name"
                    v-model="userModel.firstName"
                    outlined
                    readonly
                    hint="This field cannot be edited by a consultant only an admin"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6" sm="6">
                  <v-text-field
                    label="Last Name"
                    v-model="userModel.lastName"
                    outlined
                    readonly
                    hint="This field cannot be edited by a consultant only an admin"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" md="6" sm="6">
                  <v-text-field
                    label="Mobile"
                    v-model="userModel.telNumber"
                    outlined
                    readonly
                    hint="This field cannot be edited by a consultant only an admin"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" md="6" sm="6">
                  <v-text-field
                    label="Alternative Number"
                    v-model="userModel.altNumber"
                    outlined
                  ></v-text-field
                ></v-col>
                <v-col md="12"
                  ><v-text-field
                    label="Email"
                    v-model="userModel.email"
                    outlined
                    readonly
                    hint="This field cannot be edited by a consultant only an admin"
                  ></v-text-field>
                </v-col>
                <!-- <v-col md="12"
                  ><v-select
                    v-model="userModel.role"
                    v-if="roles && roles.roles"
                    item-text="name"
                    label="Role"
                    :items="roles.roles"
                    outlined
                  ></v-select>
                </v-col> -->
                <v-col md="12">
                  <v-row v-if="me.role === 'admin'">
                    <base-user-picker
                      :label="'Assigned Consultant'"
                      :initial="userModel.consultant"
                      :chosen-user.sync="userModel.consultant"
                      :role="'consultant'"
                      outlined
                    />
                  </v-row>
                </v-col>

                <v-col md="12">
                  <v-text-field
                    label="ID Number"
                    v-model="userModel.idNumber"
                    outlined
                    readonly
                    hint="This field cannot be edited by a consultant only an admin"
                  ></v-text-field>
                </v-col>

                <v-col md="12">
                  <v-text-field
                    label="Passport"
                    v-model="userModel.passport"
                    outlined
                    readonly
                    hint="This field cannot be edited by a consultant only an admin"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <p class="display-2">
                    <strong>Assigned relationship: </strong>
                    {{ this.userModel.relationship || "Not specified yet" }}
                  </p>
                  <base-relationship-status-picker
                    :label="'Change Relationship Status'"
                    :initial="userModel.relationship"
                    :chosen-relationship-status.sync="userModel.relationship"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-row>
                  <!-- ////new age -->
                  <v-col cols="12" md="12" sm="12">
                    <v-col>
                      <div>
                        <!-- <div class="mb-6">
                        Active picker:
                        <code>{{ this.activePicker || "null" }}</code>
                      </div> -->
                        <!-- <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="userModel.dob"
                              label="Birthday date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="userModel.dob"
                            outlined
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                            min="1900-01-01"
                            @change="saveDob"
                          ></v-date-picker>
                        </v-menu> -->
                      </div>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      label="'Address'"
                      outlined
                      counter
                      v-model="userModel.fullAddress"
                    />
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      label="'Postal Address'"
                      outlined
                      counter
                      v-model="userModel.postalAddress"
                    />
                  </v-col>
                  <v-col md="12">
                    <v-text-field
                      label="Region"
                      v-model="userModel.region"
                      placeholder="Gauteng, JHB etc"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Postal Code"
                      v-model="userModel.postalCode"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Fax Number"
                      v-model="userModel.faxNumber"
                      prefix="(+27)"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Business email"
                      v-model="userModel.businessEmail"
                      placeholder="someone@company.com"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <p class="display-2">
                      <strong>Assigned race: </strong>
                      {{ this.userModel.race || "Not specified yet" }}
                    </p>
                    <base-race-picker
                      :label="'Change Race'"
                      :initial="userModel.race"
                      :chosen-race.sync="userModel.race"
                      outlined
                    />
                  </v-col>
                  <v-col md="6">
                    <p class="display-2">
                      <strong>Assigned gender: </strong>
                      {{ this.userModel.gender || "Not specified yet" }}
                    </p>
                    <base-gender-picker
                      :label="'Change Gender'"
                      :initial="userModel.gender"
                      :chosen-gender.sync="userModel.gender"
                      outlined
                    />
                  </v-col>
                  <v-col md="6">
                    <p class="display-2">
                      <strong>Assigned Language: </strong>
                      {{ this.userModel.language || "Not specified yet" }}
                    </p>
                    <base-language-picker
                      :label="'Choose Language'"
                      :initial="userModel.language"
                      :chosen-language.sync="userModel.language"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-row>
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" v-if="userModel.id">
        <v-card>
          <v-card-text>
            <h1 class="text-md-h4 primary--text mb-5">Events</h1>
            <user-events
              :userId="userModel.id"
              :consultantId="userModel.consultant"
            />
          </v-card-text>
          <v-card-text>
            <h1 class="text-md-h4 primary--text mb-5">About the client</h1>
            <v-form ref="userForm" @submit.prevent="save()">
              <v-row>
                <v-rating
                  v-model="userModel.rating"
                  background-color="purple lighten-3"
                  half-increments
                  hover
                  length="5"
                  size="64"
                ></v-rating>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-textarea
                    v-model="userModel.bio"
                    auto-grow
                    label="Notes about client"
                    rows="10"
                    row-height="20"
                    counter
                    clearable
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn type="submit" color="success" class="text-right"
                >Save</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
        <v-card class="mt-8">
          <v-card-text>
            <v-card>
              <h1>Fica Documents</h1>
              <user-fica-products
                :userId="userModel.id"
                :consultantId="me.id"
              />
            </v-card>
          </v-card-text>
        </v-card>
        <v-card class="my-4">
          <dynamic-tabs :userId="userModel.id" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" v-if="userModel.id">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">Products</h4>
            <user-products :userId="userModel.id" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  components: {},
  data() {
    return {
      isEdit: false,
      isLoading: false,
      userModel: new User(),

      me: "",
      menu: false,
      // saveDob: "",
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {
    crumbs() {
      return [
        {
          text: "Clients",
          disabled: false,
          href: "/consultant/users",
        },
        {
          text: "Client Detail",
          disabled: false,
          href: `/consultant/user/${this.$route.params.id}`,
        },
        {
          text: `${this.isEdit ? "Edit" : "New"} User`,
          disabled: true,
        },
      ];
    },
  },
  watch: {},
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            title
            relationship
            gender
            race
            telNumber
            altNumber
            email
            businessEmail
            faxNumber
            bio
            rating
            age
            # dob
            idNumber
            passport
            fullAddress
            language
            postalAddress
            industry
            role
            referral
            branch
            branchName
            consultant
            # consultantName
            postalCode
            region
            mailingPref
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.userModel.id = this.user.id;
        this.userModel.firstName = this.user.firstName;
        this.userModel.lastName = this.user.lastName;
        this.userModel.title = this.user.title;
        this.userModel.relationship = this.user.relationship;
        this.userModel.gender = this.user.gender;
        this.userModel.race = this.user.race;
        this.userModel.telNumber = this.user.telNumber;
        this.userModel.altNumber = this.user.altNumber;
        this.userModel.email = this.user.email;
        this.userModel.businessEmail = this.user.businessEmail;
        this.userModel.faxNumber = this.user.faxNumber;
        this.userModel.rating = this.user.rating;
        this.userModel.bio = this.user.bio;
        // this.userModel.dob = this.user.dob;
        // years = this.user.age;
        // this.userModel.age = this.user.age;
        this.userModel.idNumber = this.user.idNumber;
        this.userModel.passport = this.user.passport;
        this.userModel.fullAddress = this.user.fullAddress;
        this.userModel.postalAddress = this.user.postalAddress;
        this.userModel.language = this.user.language;
        this.userModel.industry = this.user.industry;
        this.userModel.role = this.user.role;
        this.userModel.referral = this.user.referral;
        this.userModel.consultant = this.user.consultant;
        this.userModel.branch = this.user.branch;
        this.userModel.branchName = this.user.branchName;
        this.userModel.postalCode = this.user.postalCode;
        this.userModel.region = this.user.region;
        this.userModel.mailingPref = this.user.mailingPref;
      },
    },
    roles: {
      query: gql`
        query {
          __type(name: "Role") {
            roles: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    eventStatuses: {
      query: gql`
        query {
          __type(name: "EventStatus") {
            eventStatuses: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.userForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        // update
        await this.updateUser()
          .then(() => {
            this.$swal("Success!", "User was updated", "success");
          })
          .catch((e) => {
            console.log(e);
            this.$swal(
              "Error!",
              "something went wrong when trying to update the User, check data and try again later!",
              "error"
            );
          });
      } else {
        // create
        await this.createUser()
          .then(() => {
            this.$swal("Success!", "User was created", "success");
            window.location.href = `/consultant/user/${self.userModel.id}/edit`;
          })
          .catch((e) => {
            console.log(e);
            this.$swal(
              "Error!",
              `Something went wrong when creating the User, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.userModel.id,
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              title: self.userModel.title,
              relationship: self.userModel.relationship,
              gender: self.userModel.gender,
              race: self.userModel.race,
              telNumber: self.userModel.telNumber,
              altNumber: self.userModel.altNumber,
              email: self.userModel.email,
              businessEmail: self.userModel.businessEmail,
              faxNumber: self.userModel.faxNumber,
              region: self.userModel.region,
              postalCode: self.userModel.postalCode,
              bio: self.userModel.bio,
              rating: self.userModel.rating,
              // age: self.userModel.age,
              // dob: self.userModel.dob,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              postalAddress: self.userModel.postalAddress,
              language: self.userModel.language,
              industry: self.userModel.industry,
              role: self.userModel.role,
              referral: self.userModel.referral,
              branch: self.userModel.branch,
              branchName: self.userModel.branchName,
              consultant: self.userModel.consultant,
              // consultantName:self.userModel.consultantName
              mailingPref: self.userModel.mailingPref,
              updatedBy: this.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the user!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              telNumber: self.userModel.telNumber,
              email: self.userModel.email,
              role: self.userModel.role,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the User!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createUser) {
          self.userModel.id = response?.data?.createUser?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
